import Layout from "~/components/layout/layout"
import React from "react"
import SEO from "~/components/seo.js"
import Shop from "~/components/shop/shop.js"
import _ from "lodash"

const ShopCategoryPage = ({ pageContext, location }) => {
  const { products, ranges, base, name, seo, slug } = pageContext
  let flatProducts = _.flatten(products)
  let orderedProductsList = _.orderBy(flatProducts, ["name"], ["asc"])

  if (slug == "packs") {
    orderedProductsList = flatProducts.sort(function (a, b) {
      // console.log('packs ==> ', a.shopifyVariants[0])
      var firstPriceA = parseFloat(a.shopifyVariants[0].price)
      var firstPriceB = parseFloat(b.shopifyVariants[0].price)

      // Compare les prix des objets a et b
      if (firstPriceA > firstPriceB) {
        return -1 // Déplace a avant b
      } else if (firstPriceA < firstPriceB) {
        return 1 // Déplace b avant a
      } else {
        return 0 // Ne change pas l'ordre
      }
    })
  }

  const currentRange = name ? ranges.filter(range => range.name === name) : []
  const rangeLongDescription = currentRange[0]
    ? currentRange[0].gamme_content.longDescription
    : false

  let NewSeo = seo
  if (seo.metaDesc === "") NewSeo.metaDesc = rangeLongDescription

  return (
    <Layout location={location}>
      <SEO meta={NewSeo} />
      <Shop
        products={orderedProductsList}
        ranges={ranges}
        base={base}
        rangeName={name}
        rangeLongDescription={rangeLongDescription}
      />
    </Layout>
  )
}

export default ShopCategoryPage
